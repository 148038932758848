import * as React from "react";
import Content from "../components/Content";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Button from "../components/ui/Button";
import { SEO } from "../components/SEO";

function ThanksPage() {
  return (
    <>
      <Header />
      <Content light>
        <div className="rounded-xl bg-gray-50 py-16 shadow-lg sm:py-24">
          <div className="mx-auto max-w-md pl-4 pr-8 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="text-center font-serif text-4xl font-extrabold leading-10 tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl">
              Thanks for reaching out!
            </h1>
            <p className="mx-auto mt-6 max-w-3xl text-center text-xl leading-normal text-gray-500">
              We're excited to see how we can help and will contact you soon!
            </p>
            <p className="mt-6 text-center">
              <Button to="/">Back Home</Button>
            </p>
          </div>
        </div>
      </Content>
      <Footer />
    </>
  );
}

export default ThanksPage;

export const Head = () => <SEO title="Thank You" />;
